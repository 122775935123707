<template>
  <div>
    <div class="component-tit" id="pageTop">
      {{ $t("mine.lecturer_apply") }}
      <!-- 讲师申请 -->
      <div class="back" v-if="hasBack" @click="back">
        <RollbackOutlined />{{ $t("go_back") }}
        <!-- 返回 -->
      </div>
    </div>
    <a-spin :spinning="loadding">
      <a-form
        class="form-box"
        layout="vertical"
        ref="formRef"
        :model="formData"
        :colon="false"
      >
        <a-row :gutter="16" v-if="fieldList.length">
          <a-col :span="12">
            <!-- 头像 -->
            <a-form-item :label="$t('login.avatar')" name="portrait">
              <div class="avatar-box">
                <div class="left">
                  <img v-if="imageUrl" :src="imageUrl" />
                  <img
                    v-else
                    :src="require('@/assets/image/teacher_avatar.png')"
                  />
                </div>
                <div class="right">
                  <div class="btn">
                    <!-- 上传图片 -->
                    <a-button>{{ $t("upload.upload_img") }}</a-button>
                    <input
                      class="up-input"
                      ref="uploadInput"
                      type="file"
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                      @change="selectFile"
                    />
                  </div>
                  <p>
                    <InfoCircleOutlined style="margin-right: 5px" />{{
                      $t("teacher.avatar_tip")
                    }}
                    <!-- 图片上传比例为3:4，最佳上传分辨率300*400 -->
                  </p>
                </div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="defaultFieldObj.userId.dataDisplay == 1">
            <!-- 账号 -->
            <a-form-item
              :label="$t('login.account')"
              name="tel"
              style="margin-top: 88px"
            >
              <a-input :value="userInfo.account" disabled />
            </a-form-item>
          </a-col>
          <a-col
            :span="12"
            v-if="defaultFieldObj.departmentName.dataDisplay == 1"
          >
            <!-- 所属部门 -->
            <a-form-item :label="$t('teacher.department')" name="tel">
              <OpenData
                type="departmentName"
                :openid="userInfo.departmentName"
                v-if="
                  platformConfig.platform == 26 || platformConfig.platform == 31
                "
              />
              <a-input v-else :value="userInfo.departmentName" disabled />
            </a-form-item>
          </a-col>
          <a-col
            :span="12"
            v-if="defaultFieldObj.lecturerName.dataDisplay == 1"
          >
            <!-- 姓名 -->
            <a-form-item :label="$t('teacher.name')" name="tel">
              <OpenData
                type="userName"
                :openid="userInfo.realName"
                v-if="
                  platformConfig.platform == 26 || platformConfig.platform == 31
                "
              />
              <a-input v-else :value="userInfo.realName" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="defaultFieldObj.folderId.dataDisplay == 1">
            <!-- 讲师分类 -->
            <a-form-item
              :label="$t('teacher.lecturer_classification')"
              name="folderId"
              :rules="{
                required: true,
                type: 'number',
                message: $t('teacher.lecturer_classification_p'),
                // 请选择讲师分类
                trigger: 'change',
              }"
            >
              <a-tree-select
                allowClear
                v-model:value="formData.folderId"
                style="width: 100%"
                :tree-data="folders"
                :placeholder="$t('teacher.lecturer_classification_p')"
                :replaceFields="{
                  children: 'list',
                  title: 'name',
                  key: 'id',
                  value: 'id',
                }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="defaultFieldObj.levelId.dataDisplay == 1">
            <!-- 讲师等级 -->
            <a-form-item
              :label="$t('teacher.lecturer_level')"
              name="levelId"
              :rules="{
                required: true,
                type: 'number',
                message: $t('teacher.lecturer_level_p'),
                // 请选择讲师等级
                trigger: 'change',
              }"
            >
              <a-select
                v-model:value="formData.levelId"
                :placeholder="$t('teacher.lecturer_level_p')"
              >
                <a-select-option
                  :value="item.levelId"
                  v-for="item in levels"
                  :key="item.levelId"
                >
                  {{ item.levelName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="defaultFieldObj.gender.dataDisplay == 1">
            <!-- 性别 -->
            <a-form-item :label="$t('teacher.sex')" name="gender">
              <a-input
                :value="
                  userInfo.gender == 1
                    ? $t('teacher.man_woman', 1)
                    : userInfo.gender == 2
                    ? $t('teacher.man_woman', 2)
                    : ''
                "
                disabled
              />
              <!-- 男 女 -->
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="defaultFieldObj.mobile.dataDisplay == 1">
            <!-- 手机号 -->
            <a-form-item :label="$t('teacher.mobile')" name="mobile">
              <a-input :value="userInfo.mobile" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="defaultFieldObj.email.dataDisplay == 1">
            <!-- 邮箱 -->
            <a-form-item :label="$t('teacher.email')" name="email">
              <a-input :value="userInfo.email" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="defaultFieldObj.tel.dataDisplay == 1">
            <!-- 座机号 -->
            <a-form-item :label="$t('teacher.tel')" name="tel">
              <a-input :value="userInfo.tel" disabled />
            </a-form-item>
          </a-col>
          <a-col
            :span="12"
            v-if="defaultFieldObj.positionRank.dataDisplay == 1"
          >
            <!-- 职务 -->
            <a-form-item :label="$t('teacher.position')" name="positionRank">
              <a-input :value="userInfo.positionRank" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="defaultFieldObj.birthday.dataDisplay == 1">
            <!-- 出生日期 -->
            <a-form-item :label="$t('teacher.birthday')" name="birthday">
              <a-input
                :value="
                  userInfo.birthday
                    ? moment(userInfo.birthday, 'YYYYMMDD').format('YYYY-MM-DD')
                    : ''
                "
                disabled
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- 讲师课件 -->
            <a-form-item
              :label="$t('teacher.lecturer_courseware')"
              name="attachments"
              :rules="{
                required: true,
                type: 'array',
                message: $t('teacher.lecturer_courseware_p'),
                // 请上传课件
              }"
            >
              <div class="up-ctrl">
                <a-button @click="$refs.secretFileRef.show()">
                  <UploadOutlined /> {{ $t("LB_UploadingAttachments") }}
                  <!-- 上传附件 -->
                </a-button>
                <span class="tips">
                  {{ $t("upload.max_size_tip", ["2G"]) }}</span
                >
                <!-- 文件大小不能超过2G -->
              </div>
            </a-form-item>
            <ul class="upload-list" v-show="formData.attachments.length">
              <li
                class="file-item"
                v-for="(file, i) in formData.attachments"
                :key="i"
              >
                <PaperClipOutlined />
                <span class="file-item-name">
                  <template v-if="file.secretData">
                    {{ htsySecret[file.secretData] }}
                  </template>
                  {{ file.fileName }}.
                  {{ file.suffix }}
                </span>
                <DeleteOutlined class="del" @click="delFile" />
              </li>
            </ul>
          </a-col>
          <a-col :span="24" v-if="defaultFieldObj.expertTitle.dataDisplay == 1">
            <!-- 专家称号 -->
            <a-form-item :label="$t('teacher.expertTitle')" name="expertTitle">
              <a-tag
                v-for="tag in formData.expertTitleArr"
                closable
                @close="handleClose(tag)"
                :key="tag"
              >
                {{ tag }}
              </a-tag>
              <a-input
                v-if="inputVisible"
                ref="inputRef"
                type="text"
                size="small"
                :maxlength="20"
                :style="{ width: '78px' }"
                v-model:value="inputValue"
                @blur="handleInputConfirm"
                @keyup.enter="handleInputConfirm"
              />
              <a-tag
                v-else
                @click="showInput"
                style="background: #fff; border-style: dashed"
              >
                <plus-outlined />
                {{ $t("teacher.add_title") }}
                <!-- 添加称号 -->
              </a-tag>
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="defaultFieldObj.intro.dataDisplay == 1">
            <!-- 讲师简介 -->
            <a-form-item
              :label="$t('teacher.lecturer_intro')"
              name="intro"
              :rules="{
                required: defaultFieldObj.intro.dataRequired == 1,
                message: $t('PleaseEnter') + defaultFieldObj.intro.fieldName,
                trigger: 'change',
              }"
            >
              <a-textarea
                v-model:value="formData.intro"
                :placeholder="$t('teacher.lecturer_intro_p')"
                allowClear
                :maxlength="500"
                showCount
                :rows="4"
              />
              <!-- 请输入讲师简介 -->
            </a-form-item>
          </a-col>
          <template v-for="item in fieldList" :key="item.fieldKey">
            <template v-if="item.defaultField == 2 && item.dataDisplay == 1">
              <!-- 1:文本框 -->
              <a-col :span="12" v-if="item.fieldType == 1">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-input
                    v-model:value="formData[item.fieldKey]"
                    :placeholder="`${$t('PleaseEnter')}${item.fieldName}${$t(
                      'WithinNWords',
                      { total: 50 }
                    )}`"
                    allowClear
                    :maxlength="50"
                  />
                </a-form-item>
              </a-col>
              <!-- 2:下拉框 -->
              <a-col :span="12" v-if="item.fieldType == 2">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseSelect') + item.fieldName,
                    trigger: 'change',
                    type: 'object',
                  }"
                >
                  <a-select
                    allowClear
                    labelInValue
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    v-model:value="formData[item.fieldKey]"
                    :placeholder="`${$t('PleaseSelect')}${item.fieldName}`"
                    :options="item.fieldOptions"
                  />
                </a-form-item>
              </a-col>
              <!-- 4:数值 -->
              <a-col :span="12" v-if="item.fieldType == 4">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    type: 'number',
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-input-number
                    allowClear
                    v-model:value="formData[item.fieldKey]"
                    :placeholder="`${$t('PleaseEnter')}${item.fieldName}(${
                      item.len
                        ? $t('CanInputNDecimalPlace', { total: item.len })
                        : $t('PleaseEnterInteger')
                    })`"
                    :precision="item.len"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <!-- 5:日期组件 -->
              <a-col :span="12" v-if="item.fieldType == 5">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseSelect') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-date-picker
                    :getCalendarContainer="
                      (triggerNode) => triggerNode.parentNode
                    "
                    valueFormat="YYYY-MM-DD"
                    v-model:value="formData[item.fieldKey]"
                    allowClear
                    style="width: 100%"
                    :placeholder="`${$t('PleaseSelect')}${item.fieldName}`"
                  />
                </a-form-item>
              </a-col>
              <!-- 6:文本域 -->
              <a-col :span="24" v-if="item.fieldType == 6">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-textarea
                    v-model:value="formData[item.fieldKey]"
                    :placeholder="`${$t('PleaseEnter')}${item.fieldName}`"
                    allowClear
                    :maxlength="500"
                    showCount
                    :rows="4"
                  />
                </a-form-item>
              </a-col>
              <!-- 7:时间组件 -->
              <a-col :span="12" v-if="item.fieldType == 7">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-date-picker
                    :getCalendarContainer="
                      (triggerNode) => triggerNode.parentNode
                    "
                    valueFormat="YYYY-MM-DD HH:mm"
                    :show-time="{ format: 'HH:mm' }"
                    format="YYYY-MM-DD HH:mm"
                    v-model:value="formData[item.fieldKey]"
                    allowClear
                    style="width: 100%"
                    :placeholder="`${$t('PleaseSelect')}${item.fieldName}`"
                  />
                </a-form-item>
              </a-col>
              <!-- 8:树 -->
              <a-col :span="12" v-if="item.fieldType == 8">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                    type: item.isCheck == 1 ? 'array' : 'object',
                  }"
                >
                  <a-tree-select
                    allowClear
                    labelInValue
                    v-model:value="formData[item.fieldKey]"
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                    :tree-data="item.fieldOptions"
                    :placeholder="$t('PleaseSelect')"
                    treeNodeFilterProp="title"
                    :multiple="item.isCheck == 1"
                    :treeCheckable="item.isCheck == 1"
                    :showCheckedStrategy="
                      item.isSelectChild == 1 ? 'SHOW_CHILD' : 'SHOW_ALL'
                    "
                    :replaceFields="{
                      children: 'children',
                      title: 'optionValue',
                      key: 'optionKey',
                      value: 'optionKey',
                    }"
                    treeDefaultExpandAll
                  ></a-tree-select>
                </a-form-item>
              </a-col>
            </template>
          </template>
          <a-col :span="24">
            <div class="btns">
              <a-button type="primary" @click="submit">{{
                $t("CM_Confirm")
              }}</a-button>
              <!-- 确定 -->
            </div>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <!-- 上传头像 -->
    <a-modal
      v-model:visible="showDialog"
      :title="$t('teacher.upload_avatar')"
      :maskClosable="false"
    >
      <VuePictureCropper
        :boxStyle="{
          width: '300px',
          height: '400px',
          backgroundColor: '#f8f8f8',
          margin: 'auto',
        }"
        :img="pic"
        :options="{
          viewMode: 1,
          dragMode: 'move',
          aspectRatio: 300 / 400,
          cropBoxResizable: false,
        }"
        :presetMode="{
          mode: 'fixedSize',
          width: 300,
          height: 400,
        }"
      />
      <template #footer>
        <a-button @click="showDialog = false">{{ $t("CM_Cancel") }}</a-button>
        <!-- 取消 -->
        <a-button type="primary" @click="getResult">{{
          $t("teacher.crop")
        }}</a-button>
        <!-- 裁切 -->
      </template>
    </a-modal>
    <secretFile
      ref="secretFileRef"
      :config="{
        action: 'study/upload/file',
        accept: '',
        maxSize: 2048,
        sizeTip: $t('upload.max_size_tip', ['2G']),
        // 文件大小不能超过2G
      }"
      @ok="upEnd"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  getCurrentInstance,
  reactive,
  ref,
  toRefs,
  nextTick,
  computed,
} from "vue";
import { useStore } from "vuex";
import { fieldConfigList } from "@/api/other";
import { lecturerFolder, lecturerLevel, lecturerApply } from "@/api/teacher";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import request from "@/utils/request";
import ls from "@/utils/local-storage";
import moment from "moment";
import { uploadUrl, getHeader } from "@/utils/tools";
import secretFile from "@/components/secretFile.vue";
import { htsySecret } from "@/utils/business";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    VuePictureCropper,
    secretFile,
    OpenData,
  },
  props: {
    hasBack: {
      type: Boolean,
      default: false,
    },
  },
  setup(prope, { emit }) {
    const { t: $t } = useI18n();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const userInfo = ls.get("userInfo");
    const state = reactive({
      platformConfig: computed(() => store.getters.platformConfig),
      companyInfo: computed(() => store.getters.companyInfo),
      imageUrl: "",
      pic: "",
      showDialog: false,
      loadding: false,
      folders: [],
      levels: [],
      fileList: [],
      inputVisible: false,
      inputValue: "",
      fieldList: [],
      extendFields: {},
      defaultFieldObj: {},
      formData: {
        portrait: "",
        levelId: "",
        folderId: "",
        attachments: [],
        intro: "",
        motto: "",
        teachingDirection: "",
        expertAreas: "",
        expertTitle: "",
        expertTitleArr: [],
        researchAreas: "",
        workExperience: "",
        others: "",
        secretData: 0,
      },
    });

    fieldConfigList(7).then((res) => {
      let d = res.data || [];
      let obj = {};
      d.forEach((item) => {
        if (item.defaultField == 2) {
          if (item.fieldType == 2) {
            item.fieldOptions.map((opt) => {
              opt.label = opt.optionValue;
              opt.value = opt.optionKey;
            });
          }
          state.extendFields[item.fieldKey] = item;
        } else {
          obj[item.fieldKey] = item;
        }
      });
      state.defaultFieldObj = obj;
      state.fieldList = d;
    });

    lecturerFolder().then((res) => {
      state.folders = res.data || [];
    });

    lecturerLevel().then((res) => {
      state.levels = res.data || [];
    });

    const uploadInput = ref(null);
    let blobURL = "";
    const selectFile = (e) => {
      blobURL = "";
      const target = e.target;
      const { files } = target;
      if (!files) return;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        state.pic = String(reader.result);
        state.showDialog = true;
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };

    const getResult = async () => {
      const blob = await cropper.getBlob();
      const file = await cropper.getFile();
      try {
        blobURL = URL.createObjectURL(blob);
      } catch (e) {
        blobURL = "";
      }
      state.showDialog = false;
      state.imageUrl = blobURL;
      let formData = new FormData();
      formData.append("file", blob, file.name);
      request
        .post(uploadUrl + "study/me/upload/avatar", formData)
        .then((res) => {
          state.formData.portrait = res.data;
        });
    };

    const beforeUpload = (info, fileList) => {
      const isLt2G = info.size / 1024 / 1024 / 1024 < 2;
      if (!isLt2G) {
        proxy.$message.error($t("upload.max_size_tip", ["2G"]));
        // 文件大小不能超过2GB
        setTimeout(() => {
          state.fileList = [];
          state.formData.attachments = [];
        }, 0);
        return false;
      }
    };

    const secretFileRef = ref(null);
    const upLoadChange = (info) => {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        state.fileList = [info.file];
        let d = info.file.response.data;
        state.formData.attachments = [
          {
            fileName: d.name,
            filePath: d.file,
            fileSize: d.fileSize,
            fileSecertData: state.formData.secretData,
          },
        ];
      } else if (info.file.status === "error") {
        proxy.$message.error(`${info.file.name} ${$t("CM_FailUpload")}`);
        // 上传失败
      }
    };

    const upEnd = ({ secret, file }) => {
      state.formData.attachments = [
        {
          fileName: file[0].response.data.name,
          filePath: file[0].response.data.file,
          fileSize: file[0].size,
          fileType: file[0].response.data.fileType,
          secretData: secret,
          suffix: file[0].response.data.suffix,
        },
      ];
    };

    const delFile = () => {
      state.formData.attachments = [];
    };

    const inputRef = ref();
    const handleClose = (removedTag) => {
      state.formData.expertTitleArr = state.formData.expertTitleArr.filter(
        (tag) => tag !== removedTag
      );
    };

    const showInput = () => {
      state.inputVisible = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };

    const handleInputConfirm = () => {
      const inputValue = state.inputValue;
      let tags = state.formData.expertTitleArr;

      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      state.formData.expertTitleArr = tags;
      Object.assign(state, {
        inputVisible: false,
        inputValue: "",
      });
    };

    const formRef = ref(null);
    const submit = () => {
      formRef.value.validate().then(() => {
        state.loadding = true;
        let params = JSON.parse(JSON.stringify(state.formData));
        if (params.expertTitleArr) {
          params.expertTitle = params.expertTitleArr.join(",");
        }
        let customFieldMap = {};
        Object.keys(params).forEach((key) => {
          if (state.extendFields[key]) {
            let item = state.extendFields[key];
            if (item.fieldType == 2 || item.fieldType == 8) {
              customFieldMap[key] = JSON.stringify(params[key]);
            } else if (item.fieldType == 4) {
              customFieldMap[key] = String(params[key]);
            } else {
              customFieldMap[key] = params[key];
            }
          }
        });
        params.customFieldMap = customFieldMap;
        lecturerApply(params).then((res) => {
          if (res.ret == 0) {
            proxy.$message.success($t("CM_Success"));
            // 操作成功
            document.getElementById("pageTop").scrollIntoView();
            back();
          }
          state.loadding = false;
        });
      });
    };

    const back = () => {
      emit("changeKey", {
        key: "list",
      });
    };

    return {
      moment,
      uploadUrl,
      getHeader,
      userInfo,
      ...toRefs(state),
      uploadInput,
      selectFile,
      getResult,
      beforeUpload,
      upLoadChange,
      inputRef,
      handleClose,
      showInput,
      handleInputConfirm,
      formRef,
      submit,
      back,
      secretFileRef,
      upEnd,
      delFile,
      htsySecret,
    };
  },
};
</script>

<style lang="less" scoped>
.component-tit {
  font-size: 18px;
  line-height: 18px;
  color: #333;
  font-weight: bold;
  padding-left: 6px;
  margin: 4px 0 10px;
  border-left: 6px solid @color-theme;
  .mixinFlex(space-between; center);
  .back {
    color: #999;
    font-size: 14px;
    cursor: pointer;
    font-weight: normal;
    span {
      padding-right: 4px;
    }
  }
}
.form-box {
  padding-top: 14px;
  .ant-form-item {
    margin-bottom: 16px;
    ::v-deep(.ant-form-item-label) {
      & > label {
        color: #999;
      }
    }
  }
  .btns {
    text-align: center;
  }
}
.avatar-box {
  .mixinFlex(space-between; center);
  .left {
    width: 90px;
    height: 120px;
    border-radius: 6px;
    font-size: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    width: calc(100% - 104px);
    .btn {
      width: 88px;
      height: 32px;
      position: relative;
      margin-bottom: 10px;
      .up-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 88px;
        height: 32px;
        opacity: 0;
        background-color: transparent;
        color: transparent;
        cursor: pointer;
      }
    }
    p {
      color: #999999;
      font-size: 12px;
      margin: 0;
    }
  }
}
.up-ctrl {
  .tips {
    color: #999;
    padding-left: 16px;
  }
}
.upload-list {
  .file-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #f5f5f5;
    &-name {
      flex: 1;
      margin: 0 10px;
    }
  }
}
</style>
